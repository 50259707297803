import * as React from 'react';
import { Container } from 'ui/components/organisms/container/container';
import { Row, Col } from 'ui/components/organisms/grid/grid';
import Instagram from 'ui/components/atoms/icons/instagram.inline.svg';
import Facebook from 'ui/components/atoms/icons/facebook.inline.svg';
import Youtube from 'ui/components/atoms/icons/youtube.inline.svg';
import { TextPriority2, Spacing, TextRegularAlt } from 'ui/components/atoms/typography/typography';
import { Social } from 'ui/components/atoms/social/social';
import styles from './banner_contact.module.css';
import { Directions } from 'ui/components/atoms/directions/directions';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { Picture } from 'ui/components/atoms/picture/picture';
import WatermarkO from 'ui/components/atoms/watermarks/watermarkPale-O.svg';
import { WatermarksBanner } from 'ui/components/atoms/watermarks/watermarksBanner';
import { WYSIWYG } from '../wysiwyg/wysiwyg';

export type BannerContactProps = {
  heading: string;
  description: string;
  telephone: string;
  directions: {
    label: string;
    href: string;
  };
  email: string;
  image: null | WPImageFluid;
  openingHours: {
    heading: string;
    times: {
      day: string;
      time: string;
    }[];
  };
  socialMedia: {
    youtube: string;
    instagram: string;
    facebook: string;
  };
};

export const BannerContact = ({
  image,
  heading,
  description,
  openingHours,
  telephone,
  email,
  directions,
  socialMedia: { instagram, youtube, facebook },
}: BannerContactProps) => (
  <div className={styles.banner}>
    <div className={styles.image}>
      <Container>
        <Row>
          <Col className={styles.imageColumn} sm={12} md={6} mdOffset={6} lg={5}>
            <div className={styles.picture}>
              <Picture
                type="fluid"
                fluid={image?.localFile.childImageSharp.fluid}
                placeholder="285x190"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <Container className={styles.topContent}>
      {image ? (
        <WatermarksBanner
          variant="O"
          aspectRatio={1 / image.localFile.childImageSharp.fluid.aspectRatio}
        />
      ) : null}
      <div className={styles.watermarksMobile}>
        <img src={WatermarkO} />
      </div>
      <Row center="sm" start="md">
        <Col sm={12} md={6} lg={5} lgOffset={1}>
          <div className={styles.textContent}>
            <TextPriority2 tagName="h1" bottomSpacing={Spacing.SPACING_16}>
              {heading}
            </TextPriority2>
            <div className={styles.description}>
              <TextRegularAlt tagName="div">
                <WYSIWYG html={description} />
              </TextRegularAlt>
            </div>
            <Directions href={directions.href} label={directions.label} />
            <TextRegularAlt bottomSpacing={Spacing.SPACING_24}>
              <a className={styles.telephone} href={`tel:${telephone}`}>
                {telephone}
              </a>
            </TextRegularAlt>
            <a href={`mailto:${email}`} className={styles.mail}>
              <TextRegularAlt bottomSpacing={Spacing.SPACING_40}>{email}</TextRegularAlt>
            </a>
            <div className={styles.social}>
              <a href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noreferrer">
                <Social icon={Instagram} text="Instagram" />
              </a>
              <a href={`https://www.facebook.com/${facebook}`} target="_blank" rel="noreferrer">
                <Social icon={Facebook} text="Facebook" />
              </a>
              <a href={`https://www.youtube.com/${youtube}`} target="_blank" rel="noreferrer">
                <Social icon={Youtube} text="Youtube" />
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>

    <div className={styles.bottomContent}>
      <Container>
        <Row center="sm" start="md">
          <Col sm={12} md={6} lg={5} lgOffset={1}>
            <TextPriority2 color="white" tagName="h1" bottomSpacing={Spacing.SPACING_32}>
              {openingHours.heading}
            </TextPriority2>
            <div className={styles.openingHours}>
              {openingHours.times.map((x, i) => (
                <div key={i} className={styles.openingHoursItem}>
                  <TextRegularAlt
                    bottomSpacing={Spacing.SPACING_24}
                    tagName="div"
                    className={styles.openingHoursDays}
                    color="white">
                    {x.day}
                  </TextRegularAlt>
                  <TextRegularAlt tagName="div" color="white">
                    <strong>{x.time}</strong>
                  </TextRegularAlt>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);
