import { Contact } from 'ui/components/pages/contact/contact';
import { graphql } from 'gatsby';
import { WPImageFluid } from 'shared/types/wp_localImage';

export const query = graphql`
  query ContactPageQuery($id: String!) {
    allInstaNode {
      ...InstagramFeed
    }
    wpPage(id: { eq: $id }) {
      ...PageContent
      acfTemplateContact {
        contact {
          banner {
            heading
            description
            email
            telephone
            directions {
              label
              href
            }
            image {
              localFile {
                ...FluidImageFull
              }
            }
          }
          openingHours {
            heading
            times {
              day
              time
            }
          }
        }
      }
    }
    wpGlobal: wp {
      ...WpGlobal
    }
  }
`;

// TODO: Generate these via Apollo Codegen
export type ContactPageProps = {
  acfTemplateContact: {
    contact: {
      banner: {
        heading: string;
        description: string;
        email: string;
        telephone: string;
        directions: {
          label: string;
          href: string;
        };
        image: null | WPImageFluid;
      };
      openingHours: {
        heading: string;
        times: {
          day: string;
          time: string;
        }[];
      };
    };
  };
};

export default Contact;
