import * as React from 'react';
import { PageTemplateProps } from 'shared/types/pages';
import { Instagram } from 'ui/components/molecules/instagram/instagram';
import { BannerContact } from 'ui/components/molecules/banner/banner_contact';
import { ContactPageProps } from 'templates/contact';
import { createRestrictedModal } from 'ui/components/molecules/modal/modal';
import { Main } from 'ui/components/organisms/main/main';
import { JsonLD } from 'ui/components/molecules/json_ld/json_ld';

export const Contact = ({ data }: PageTemplateProps<ContactPageProps>) => {
  const { breadcrumbs } = data.wpPage.seo;
  const { banner, openingHours } = data.wpPage.acfTemplateContact.contact;
  const {
    instagram: { handle: instagram },
    facebook: { handle: facebook },
    youtube: { handle: youtube },
  } = data.wpGlobal.globalOptions.acfGlobalOptions;

  const { RestrictedModal, store } = createRestrictedModal({
    acfSharedPage: data.wpPage.acfSharedPage,
  });
  return (
    <React.Fragment>
      <JsonLD
        title={data.wpPage.title}
        acfSharedPage={data.wpPage.acfSharedPage}
        breadcrumbs={breadcrumbs}
      />
      <Main isBlurred={store.isVisible}>
        <BannerContact
          socialMedia={{ facebook, youtube, instagram }}
          openingHours={openingHours}
          {...banner}
        />
        <Instagram handle={instagram} posts={data.allInstaNode || []} />
      </Main>
      <RestrictedModal />
    </React.Fragment>
  );
};
