import * as React from 'react';
import { TextSub1 } from '../typography/typography';
import Arrow from 'ui/components/atoms/icons/arrow.inline.svg';
import styles from './directions.module.css';

export const Directions = ({ label, href }: { label: string; href: string }) => {
  return (
    <div className={styles.container}>
      <TextSub1 tagName="div">
        <a target="_blank" rel="noreferrer" href={href} className={styles.label}>
          {label}
        </a>
      </TextSub1>
      <div className={styles.icon}>
        <Arrow />
      </div>
    </div>
  );
};
